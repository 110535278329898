<template>
	<div class="order-main">
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				
			}
		},
		created() {
			
		},
		methods: {
			
		},
	}
</script>

<style lang="scss" scoped>
	.order-main{padding: 18px;width: 100%;height: 100%;box-sizing: border-box;overflow: scroll;}
</style>